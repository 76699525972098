@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply font-FilsonPro font-normal text-base text-dark bg-white scroll-smooth;
    }

    *:focus {
        outline: none;
    }
    /*main {*/
    /*    contain: paint;*/
    /*}*/
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        @apply text-inherit m-0;
    }

    h1 {
        @apply font-TanNimbus text-4xl leading-[1.33] lg:leading-[1.28] lg:text-7xl;
    }

    h2 {
        @apply font-FilsonPro text-[30px] lg:text-[40px] leading-[1.15] font-bold;
    }

    h3 {
        @apply font-FilsonPro text-xl leading-6 font-medium lg:text-2xl lg:leading-7;
    }

    p {
        @apply text-base font-normal leading-[1.5] font-FilsonPro;
    }

    img {
        @apply h-auto w-full object-cover;
    }

    a:hover {
        @apply text-green-brand;
    }

    button:hover:not[disabled] {
        @apply text-green-brand;
    }

    article p,
    article ul,
    article ol {
        @apply mb-4;
    }

    article ul,
    article ol {
        @apply list-outside pl-5;
    }

    article ul {
        @apply list-disc;
    }

    article ol {
        @apply list-decimal;
    }


}

@layer components {
    .btn {
        @apply [&svg]:flex-shrink-0 rounded text-white font-medium inline-flex relative box-border justify-center outline-0 cursor-pointer select-none items-center text-base text-center decoration-0 transform-none overflow-hidden lg:w-fit w-full [&>span>svg]:shrink-0 disabled:bg-gray-500 disabled:hover:cursor-default;
        padding: 8px 16px;
        transition: all .2s ease, background-color 0s;
        -webkit-transition: all .2s ease, background-color 0s;
        -webkit-tap-highlight-color: transparent;
    }

    .btn > svg {
        transition: all .2s ease;
    }

    .btn > svg > line {
        transition: all .2s ease;
    }

    .btn > span,
    .btn > a,
    .btn > p {
        @apply font-bold text-base leading-[21px];
    }

    .btn-primary {
        @apply bg-dark h-[51px] max-w-full lg:w-[350px] rounded-[60px] lg:rounded-[90px];
    }

    .btn-submit {
        @apply bg-dark h-[51px] max-w-full;
    }

    .btn-primary:hover:enabled {
        @apply after:content-[""] bg-transparent [&>span]:relative [&>span]:z-10 after:absolute after:w-full after:h-full overflow-visible after:rounded-full after:rotate-[-1deg] after:bg-green-brand [&>span]:text-black [&>svg]:relative [&>svg]:z-10 [&>svg]:fill-dark [&>svg>path]:fill-dark [&>svg>path]:stroke-dark [&>svg>circle]:text-dark;
    }


    .btn-menu {
        @apply bg-white rounded-full w-10 h-10 !p-0;
    }

    .btn-menu:hover {
        @apply bg-transparent [&>div]:rotate-[-6deg] [&>svg]:rotate-[-6deg] bg-green-brand;
    }

    .btn-menu-dark {
        @apply bg-black rounded-full w-10 h-10 !p-0;
    }

    .btn-menu-dark:hover {
        @apply [&>div>span]:bg-black bg-transparent [&>div]:rotate-[-6deg] [&>svg]:rotate-[-6deg] bg-green-brand;
    }

    .btn-secondary {
        @apply bg-[#8a8a8a33] text-[#242424] rounded-[60px] py-[14px] px-[30px];
    }

    .btn-secondary:hover:enabled {
        @apply after:rounded-[60px] after:rotate-[-1deg] bg-transparent after:bg-transparentGrey after:absolute after:w-full after:h-full [&>span]:z-10 overflow-visible [&>span]:relative [&>span]:text-white [&>svg]:relative [&>svg]:z-10 [&>svg]:fill-white [&>svg]:stroke-[#FFFFFF] [&>svg>path]:fill-white [&>svg>path]:stroke-[#FFFFFF]
    }

    .btn-icon {
        @apply px-9 py-3.5 bg-dark h-[51px] min-w-0 rounded-[60px] lg:rounded-[90px];
    }

    .btn-icon:hover:enabled {
        @apply rotate-[-1deg] bg-green-brand text-black [&>svg]:fill-dark [&>svg>path]:fill-dark [&>svg>path]:stroke-dark
    }

    .btn-icon-white {
        @apply px-10 min-w-0 bg-white h-[51px] rounded-[60px] lg:rounded-[90px] text-dark [&>svg]:fill-dark [&>span]:whitespace-nowrap;
    }

    .btn-icon-white:hover {
        @apply after:content-[""] bg-transparent [&>span]:relative [&>span]:z-10 after:absolute after:w-full after:h-full overflow-visible after:rounded-full after:rotate-[-1deg] after:bg-green-brand [&>svg]:relative [&>svg]:z-10;
    }

    .btn-primary:hover:enabled {
        @apply after:content-[""] bg-transparent [&>span]:relative [&>span]:z-10 after:absolute after:w-full after:h-full overflow-visible after:rounded-full after:rotate-[-1deg] after:bg-green-brand [&>span]:text-black [&>svg]:relative [&>svg]:z-10 [&>svg]:fill-dark [&>svg>path]:fill-dark [&>svg>path]:stroke-dark;
    }

    .btn-white {
        @apply bg-white h-[51px] lg:min-w-[350px] rounded-[60px] lg:rounded-[90px] text-dark [&>svg]:fill-dark;
    }

    .btn-white:hover:enabled {
        @apply invert;
    }

    .btn-primary:active {
    }

    .btn-round {
        @apply bg-dark w-[30px] h-[30px] rounded-full p-2.5 flex shrink-0 origin-center;
    }

    .btn-round:hover:enabled {
        @apply [&>svg>path]:stroke-black [&>span>svg]:rotate-[-6deg] [&span]:rotate-[-51deg] bg-green-brand text-black
    }

    .btn-round:active {
    }

    .btn-danger {
        @apply bg-white border-[1px] h-[51px] text-red-500 border-red-500 rounded-full p-2.5;
    }

    .btn-danger:hover:enabled {
        @apply after:rotate-[-1deg] after:w-full after:h-full after:absolute overflow-visible border-none [&>svg]:relative [&>svg]:z-10 [&>svg>line]:stroke-white bg-transparent after:rounded-full after:bg-red-d [&>span]:text-white [&>span]:relative [&>span]:z-10;
    }

    .btn-resume {
        @apply bg-white border-[1px] h-[51px] text-green-e border-[2px] border-green-f rounded-full p-2.5;
    }

    .btn-resume:hover:enabled {
        @apply rotate-[-1deg] border-none [&>svg>path]:fill-white bg-green-g text-white;
    }


    .btn-outlined {
        @apply font-bold h-[51px] w-full bg-white border-[2px] text-dark/70 border-dark/70 rounded-full p-2.5;
    }

    .btn-outlined:hover:enabled {
        @apply after:rounded-full after:bg-dark/70 after:rotate-[-1deg] after:w-full after:h-full after:absolute [&>span]:text-white [&>span]:relative [&>span]:z-10 bg-transparent overflow-visible border-none text-white !important;
    }

    .error {
        @apply text-sm text-red-500 mt-1;
    }

    /*.succes {*/
    /*    @apply mt-1 text-sm;*/
    /*}*/
    .input, .textarea, .select {
        @apply border-[1px] border-grey/20 text-dark rounded overflow-hidden focus:border-[1px] focus:border-dark;
    }

    .label {
        @apply text-dark mb-1.5 text-sm leading-4 font-bold;
    }

    input[type="checkbox"] {
        @apply border-[1px] border-dark rounded;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
        background-repeat: no-repeat;
        background-position: right 20px top 50%;
    }

    td {
        padding: 8px;
        white-space: nowrap;
        border-right: 1px solid black;
        border-bottom: 1px solid black;
    }

    td:first-child {
        border-left: 1px solid black;
    }

    tr:first-child {
        border-top: 1px solid black;
    }

    .gm-style .gm-style-iw + div {
        @apply hidden opacity-0 invisible
    }

    .gm-style-iw-t {
        @apply overflow-visible !important
    }

    .gm-style .gm-style-iw-c {
        @apply min-w-[330px] overflow-visible !important
    }

    .gm-style .gm-style-iw-c button {
        @apply -right-4 -top-4 bg-white rounded-full border-black/20 border opacity-100 !important
    }

    .gm-ui-hover-effect {
        @apply opacity-100 !important
    }

    .clusterSmall > div {
        @apply leading-[95px] !important
    }

    .clusterMedium > div{
        @apply leading-[112px] !important
    }

    .clusterLarge > div {
        @apply leading-[128px] !important;
    }

        /*TODO: if possible inherit font styles from parent*/
    .green-underline {
        @apply w-fit text-inherit z-10 inline-block relative after:content-[''] after:-z-10 after:absolute after:bottom-[0.15em] after:-left-2.5 after:w-[calc(100%+1rem)] after:rounded-[10px] after:h-3 after:bg-green-brand;
    }

    .swiper-horizontal > .swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal {
        @apply !mt-10 !relative !w-full !left-0 !right-0 !h-[5px] !bg-[rgba(138,138,138,0.1)];
    }

    .swiper-scrollbar-drag {
        @apply !bg-green-a !cursor-grab;
    }

    .menuTransition {
        transition: height .5s ease, background-color ease-in .3s, transform .5s ease-out;
    }

    .custom-gradient {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    }

    .image-gradient {
        background: linear-gradient(0deg, rgba(36, 36, 36, 0.5) 0%, rgba(36, 36, 36, 0) 85.24%);
    }

    .hide-scrollbar {
        scrollbar-width: none;

    }

    .hide-scrollbar::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
    }


}
